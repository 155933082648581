import { Injectable } from '@angular/core';
import { AllNotification_Mr } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class AllNotification_MrService extends BaseService<AllNotification_Mr> {
  
    constructor() {
        super('AllNotification_Mr');
    }
}
