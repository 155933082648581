
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthAdminGuard } from "./pages/auth/guard/authGuardAdmin.service";
import { UsersProfileComponent } from "./pages/auth/users-profile/users-profile.component";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: "register1", loadChildren: () => import("./pages/auth/register/register.module").then((m) => m.RegisterModule), },
  { path: "login", loadChildren: () => import("./pages/auth/login/login.module").then((m) => m.LoginModule) },
  { path: "verify", loadChildren: () => import("./pages/auth/verification/verification.module").then((m) => m.VerificationModule) },

  // For Admin Route
  // { path: 'admin/home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'admin/home', loadChildren: () => import('./pages/adminCustom/homes/homes.module').then(m => m.HomesModule), canActivate: [AuthAdminGuard] },
  { path: 'admin/users', loadChildren: () => import('./pages/admin/users/users.module').then(m => m.UsersModule) , canActivate: [AuthAdminGuard]},
  { path: 'admin/enumDetail', loadChildren: () => import('./pages/admin/enumDetails/enumDetails.module').then(m => m.EnumDetailsModule), canActivate: [AuthAdminGuard] },

  { path: 'admin/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule), canActivate: [AuthAdminGuard] },
  { path: 'admin/farmer/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule), canActivate: [AuthAdminGuard] },
  { path: 'admin/dealer/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/billingAddresses', loadChildren: () => import('./pages/adminCustom/billingAddresses/billingAddresses.module').then(m => m.BillingAddressesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/carts', loadChildren: () => import('./pages/adminCustom/carts/carts.module').then(m => m.CartsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/commonDatas', loadChildren: () => import('./pages/adminCustom/commonDatas/commonDatas.module').then(m => m.CommonDatasModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/commonDataDefaults', loadChildren: () => import('./pages/adminCustom/commonDataDefaults/commonDataDefaults.module').then(m => m.CommonDataDefaultsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/configurations', loadChildren: () => import('./pages/admin/configurations/configurations.module').then(m => m.ConfigurationsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/contacts', loadChildren: () => import('./pages/adminCustom/contacts/contacts.module').then(m => m.ContactsModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/contentHtmls', loadChildren: () => import('./pages/adminCustom/contentHtmls/contentHtmls.module').then(m => m.ContentHtmlsModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/contentHtmlDefaults', loadChildren: () => import('./pages/adminCustom/contentHtmlDefaults/contentHtmlDefaults.module').then(m => m.ContentHtmlDefaultsModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/documents', loadChildren: () => import('./pages/adminCustom/documents/documents.module').then(m => m.DocumentsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/i18n', loadChildren: () => import('./pages/admin/i18n/i18n.module').then(m => m.I18nModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/importDatas', loadChildren: () => import('./pages/admin/importDatas/importDatas.module').then(m => m.ImportDatasModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/logins', loadChildren: () => import('./pages/admin/logins/logins.module').then(m => m.LoginsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/buyOrders', loadChildren: () => import('./pages/adminCustom/buyOrders/buyOrders.module').then(m => m.BuyOrdersModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/allDocumentUploads', loadChildren: () => import('./pages/adminCustom/allDocumentUploads/allDocumentUploads.module').then(m => m.AllDocumentUploadsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/banners', loadChildren: () => import('./pages/adminCustom/banners/banners.module').then(m => m.BannersModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/categories', loadChildren: () => import('./pages/adminCustom/categories/categories.module').then(m => m.CategoriesModule), canActivate: [AuthAdminGuard] },
// { path: 'admin/categorie_Hi', loadChildren: () => import('./pages/adminCustom/category_Hi/category_Hi.module').then(m => m.Category_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/category_Hi', loadChildren: () => import('./pages/adminCustom/category_Hi/category_Hi.module').then(m => m.Category_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/category_Mr', loadChildren: () => import('./pages/adminCustom/category_Mr/category_Mr.module').then(m => m.Category_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/coupons', loadChildren: () => import('./pages/adminCustom/coupons/coupons.module').then(m => m.CouponsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/crops', loadChildren: () => import('./pages/adminCustom/crops/crops.module').then(m => m.CropsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/cropDiseases', loadChildren: () => import('./pages/adminCustom/cropDiseases/cropDiseases.module').then(m => m.CropDiseasesModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/cropDiseaseMappings', loadChildren: () => import('./pages/adminCustom/cropDiseaseMappings/cropDiseaseMappings.module').then(m => m.CropDiseaseMappingsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/districts', loadChildren: () => import('./pages/adminCustom/districts/districts.module').then(m => m.DistrictsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/farms', loadChildren: () => import('./pages/adminCustom/farms/farms.module').then(m => m.FarmsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/farmSoilDatas', loadChildren: () => import('./pages/adminCustom/farmSoilDatas/farmSoilDatas.module').then(m => m.FarmSoilDatasModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/farmWeatherDatas', loadChildren: () => import('./pages/adminCustom/farmWeatherDatas/farmWeatherDatas.module').then(m => m.FarmWeatherDatasModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/forgetPasswords', loadChildren: () => import('./pages/admin/forgetPasswords/forgetPasswords.module').then(m => m.ForgetPasswordsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/labs', loadChildren: () => import('./pages/adminCustom/labs/labs.module').then(m => m.LabsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/labAppointments', loadChildren: () => import('./pages/adminCustom/labAppointments/labAppointments.module').then(m => m.LabAppointmentsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/notifications', loadChildren: () => import('./pages/adminCustom/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/notification_Hi', loadChildren: () => import('./pages/adminCustom/notification_Hi/notification_Hi.module').then(m => m.Notification_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/notification_Mr', loadChildren: () => import('./pages/adminCustom/notification_Mr/notification_Mr.module').then(m => m.Notification_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/packageSubscriptionHistories', loadChildren: () => import('./pages/adminCustom/packageSubscriptionHistories/packageSubscriptionHistories.module').then(m => m.PackageSubscriptionHistoriesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plotFaqMappings', loadChildren: () => import('./pages/adminCustom/plotFaqMappings/plotFaqMappings.module').then(m => m.PlotFaqMappingsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plotFaqQueAns', loadChildren: () => import('./pages/adminCustom/plotFaqQueAns/plotFaqQueAns.module').then(m => m.PlotFaqQueAnsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/products', loadChildren: () => import('./pages/adminCustom/products/products.module').then(m => m.ProductsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/productImages', loadChildren: () => import('./pages/adminCustom/productImages/productImages.module').then(m => m.ProductImagesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/productTags', loadChildren: () => import('./pages/adminCustom/productTags/productTags.module').then(m => m.ProductTagsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/pushNotificationMappers', loadChildren: () => import('./pages/adminCustom/pushNotificationMappers/pushNotificationMappers.module').then(m => m.PushNotificationMappersModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/pushNotificationMessages', loadChildren: () => import('./pages/adminCustom/pushNotificationMessages/pushNotificationMessages.module').then(m => m.PushNotificationMessagesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/states', loadChildren: () => import('./pages/adminCustom/states/states.module').then(m => m.StatesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/subscriptionPackages', loadChildren: () => import('./pages/adminCustom/subscriptionPackages/subscriptionPackages.module').then(m => m.SubscriptionPackagesModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/subscriptionPackage_Hi', loadChildren: () => import('./pages/adminCustom/subscriptionPackage_Hi/subscriptionPackage_Hi.module').then(m => m.SubscriptionPackage_HiModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/subscriptionPackage_Mr', loadChildren: () => import('./pages/adminCustom/subscriptionPackage_Mr/subscriptionPackage_Mr.module').then(m => m.SubscriptionPackage_MrModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/wallets', loadChildren: () => import('./pages/adminCustom/wallets/wallets.module').then(m => m.WalletsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/walletTransactions', loadChildren: () => import('./pages/adminCustom/walletTransactions/walletTransactions.module').then(m => m.WalletTransactionsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/wishLists', loadChildren: () => import('./pages/adminCustom/wishLists/wishLists.module').then(m => m.WishListsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/commentLikes', loadChildren: () => import('./pages/adminCustom/commentLikes/commentLikes.module').then(m => m.CommentLikesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/cropCategories', loadChildren: () => import('./pages/adminCustom/cropCategories/cropCategories.module').then(m => m.CropCategoriesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/deficiencyDisorders', loadChildren: () => import('./pages/adminCustom/deficiencyDisorders/deficiencyDisorders.module').then(m => m.DeficiencyDisordersModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/deficiencyDisorder_Hi', loadChildren: () => import('./pages/adminCustom/deficiencyDisorder_Hi/deficiencyDisorder_Hi.module').then(m => m.DeficiencyDisorder_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/deficiencyDisorder_Mr', loadChildren: () => import('./pages/adminCustom/deficiencyDisorder_Mr/deficiencyDisorder_Mr.module').then(m => m.DeficiencyDisorder_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/discusses', loadChildren: () => import('./pages/adminCustom/discusses/discusses.module').then(m => m.DiscussesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/discussComments', loadChildren: () => import('./pages/adminCustom/discussComments/discussComments.module').then(m => m.DiscussCommentsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plantProtections', loadChildren: () => import('./pages/adminCustom/plantProtections/plantProtections.module').then(m => m.PlantProtectionsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plantProtection_Hi', loadChildren: () => import('./pages/adminCustom/plantProtection_Hi/plantProtection_Hi.module').then(m => m.PlantProtection_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plantProtection_Mr', loadChildren: () => import('./pages/adminCustom/plantProtection_Mr/plantProtection_Mr.module').then(m => m.PlantProtection_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/productFaqs', loadChildren: () => import('./pages/adminCustom/productFaqs/productFaqs.module').then(m => m.ProductFaqsModule) , canActivate: [AuthAdminGuard]},
{ path: 'admin/seasonWises', loadChildren: () => import('./pages/adminCustom/seasonWises/seasonWises.module').then(m => m.SeasonWisesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/otps', loadChildren: () => import('./pages/adminCustom/otps/otps.module').then(m => m.OtpsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/homes', loadChildren: () => import('./pages/admin/homes/homes.module').then(m => m.HomesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/enumDetails', loadChildren: () => import('./pages/adminCustom/enumDetails/enumDetails.module').then(m => m.EnumDetailsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/orderDetails', loadChildren: () => import('./pages/admin/orderDetails/orderDetails.module').then(m => m.OrderDetailsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/orderDetails', loadChildren: () => import('./pages/adminCustom/orderDetails/orderDetails.module').then(m => m.OrderDetailsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/myPloatCalanders', loadChildren: () => import('./pages/adminCustom/myPloatCalanders/myPloatCalanders.module').then(m => m.MyPloatCalandersModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/myPlots', loadChildren: () => import('./pages/adminCustom/myPlots/myPlots.module').then(m => m.MyPlotsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/pincodeMasters', loadChildren: () => import('./pages/adminCustom/pincodeMasters/pincodeMasters.module').then(m => m.PincodeMastersModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/soilReports', loadChildren: () => import('./pages/admin/soilReports/soilReports.module').then(m => m.SoilReportsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/discussTypes', loadChildren: () => import('./pages/adminCustom/discussTypes/discussTypes.module').then(m => m.DiscussTypesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/limitedTimeSales', loadChildren: () => import('./pages/adminCustom/limitedTimeSales/limitedTimeSales.module').then(m => m.LimitedTimeSalesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/limitedTimeSale_Hi', loadChildren: () => import('./pages/adminCustom/limitedTimeSale_Hi/limitedTimeSale_Hi.module').then(m => m.LimitedTimeSale_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/limitedTimeSale_Mr', loadChildren: () => import('./pages/adminCustom/limitedTimeSale_Mr/limitedTimeSale_Mr.module').then(m => m.LimitedTimeSale_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/productLives', loadChildren: () => import('./pages/adminCustom/productLives/productLives.module').then(m => m.ProductLivesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/feedBacks', loadChildren: () => import('./pages/adminCustom/feedBacks/feedBacks.module').then(m => m.FeedBacksModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/raiseTickets', loadChildren: () => import('./pages/adminCustom/raiseTickets/raiseTickets.module').then(m => m.RaiseTicketsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/allNotifications', loadChildren: () => import('./pages/adminCustom/allNotifications/allNotifications.module').then(m => m.AllNotificationsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/allNotification_Hi', loadChildren: () => import('./pages/adminCustom/allNotification_Hi/allNotification_Hi.module').then(m => m.AllNotification_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/allNotification_Mr', loadChildren: () => import('./pages/adminCustom/allNotification_Mr/allNotification_Mr.module').then(m => m.AllNotification_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plotPayments', loadChildren: () => import('./pages/adminCustom/plotPayments/plotPayments.module').then(m => m.PlotPaymentsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/plotPayment', loadChildren: () => import('./pages/admin/plotPayments/plotPayments.module').then(m => m.PlotPaymentsModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/userNotifies', loadChildren: () => import('./pages/adminCustom/userNotifies/userNotifies.module').then(m => m.UserNotifiesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/premiumPackages', loadChildren: () => import('./pages/adminCustom/premiumPackages/premiumPackages.module').then(m => m.PremiumPackagesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/premiumPackage_Hi', loadChildren: () => import('./pages/adminCustom/premiumPackage_Hi/premiumPackage_Hi.module').then(m => m.PremiumPackage_HiModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/premiumPackage_Mr', loadChildren: () => import('./pages/adminCustom/premiumPackage_Mr/premiumPackage_Mr.module').then(m => m.PremiumPackage_MrModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/product_Hi', loadChildren: () => import('./pages/adminCustom/product_Hi/product_Hi.module').then(m => m.Product_HiModule) },
{ path: 'admin/product_Mr', loadChildren: () => import('./pages/adminCustom/product_Mr/product_Mr.module').then(m => m.Product_MrModule) },
{ path: 'admin/cropDisease_Hi', loadChildren: () => import('./pages/adminCustom/cropDisease_Hi/cropDisease_Hi.module').then(m => m.CropDisease_HiModule) },
{ path: 'admin/cropDisease_Mr', loadChildren: () => import('./pages/adminCustom/cropDisease_Mr/cropDisease_Mr.module').then(m => m.CropDisease_MrModule) },
{ path: 'admin/plotFaqQueAns_Hi', loadChildren: () => import('./pages/adminCustom/plotFaqQueAns_Hi/plotFaqQueAns_Hi.module').then(m => m.PlotFaqQueAns_HiModule) },
{ path: 'admin/plotFaqQueAns_Mr', loadChildren: () => import('./pages/adminCustom/plotFaqQueAns_Mr/plotFaqQueAns_Mr.module').then(m => m.PlotFaqQueAns_MrModule) },
{ path: 'admin/pushNotificationMessage_Hi', loadChildren: () => import('./pages/adminCustom/pushNotificationMessage_Hi/pushNotificationMessage_Hi.module').then(m => m.PushNotificationMessage_HiModule) },
{ path: 'admin/pushNotificationMessage_Mr', loadChildren: () => import('./pages/adminCustom/pushNotificationMessage_Mr/pushNotificationMessage_Mr.module').then(m => m.PushNotificationMessage_MrModule) },
{ path: 'admin/productLive_Hi', loadChildren: () => import('./pages/adminCustom/productLive_Hi/productLive_Hi.module').then(m => m.ProductLive_HiModule) },
{ path: 'admin/productLive_Mr', loadChildren: () => import('./pages/adminCustom/productLive_Mr/productLive_Mr.module').then(m => m.ProductLive_MrModule) },
{ path: 'admin/crop_Hi', loadChildren: () => import('./pages/adminCustom/crop_Hi/crop_Hi.module').then(m => m.Crop_HiModule) },
{ path: 'admin/crop_Mr', loadChildren: () => import('./pages/adminCustom/crop_Mr/crop_Mr.module').then(m => m.Crop_MrModule) },
{ path: 'admin/enumDetail_Hi', loadChildren: () => import('./pages/adminCustom/enumDetail_Hi/enumDetail_Hi.module').then(m => m.EnumDetail_HiModule) },
{ path: 'admin/enumDetail_Mr', loadChildren: () => import('./pages/adminCustom/enumDetail_Mr/enumDetail_Mr.module').then(m => m.EnumDetail_MrModule) },
// <<route-path-admin>>
  // End Admin Route

  // For AppUser Route
  // { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  // { path: 'home', component: HomeComponent },
  // { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },

  { path: 'home', loadChildren: () => import('./pages/custom/homes/homes.module').then(m => m.HomesModule) },
  { path: 'profile', component: UsersProfileComponent },
  { path: 'users', loadChildren: () => import('./pages/user/users/users.module').then(m => m.UsersModule) },
  { path: 'webUsers', loadChildren: () => import('./pages/user/webUsers/webUsers.module').then(m => m.WebUsersModule) },
  { path: 'tasks', loadChildren: () => import('./pages/user/tasks/tasks.module').then(m => m.TasksModule) },
  { path: 'projects', loadChildren: () => import('./pages/user/projects/projects.module').then(m => m.ProjectsModule) },
  { path: 'projects/details', loadChildren: () => import('./pages/user/project-details/project-details.module').then(m => m.ProjectDetailsModule) },

  { path: 'appUsers', loadChildren: () => import('./pages/custom/appUsers/appUsers.module').then(m => m.AppUsersModule) },
{ path: 'allDocumentUploads', loadChildren: () => import('./pages/user/allDocumentUploads/allDocumentUploads.module').then(m => m.AllDocumentUploadsModule) },
{ path: 'districts', loadChildren: () => import('./pages/user/districts/districts.module').then(m => m.DistrictsModule) },
{ path: 'pushNotificationMappers', loadChildren: () => import('./pages/user/pushNotificationMappers/pushNotificationMappers.module').then(m => m.PushNotificationMappersModule) },
{ path: 'pushNotificationMessages', loadChildren: () => import('./pages/user/pushNotificationMessages/pushNotificationMessages.module').then(m => m.PushNotificationMessagesModule) },
{ path: 'states', loadChildren: () => import('./pages/user/states/states.module').then(m => m.StatesModule) },
{ path: 'commentLikes', loadChildren: () => import('./pages/user/commentLikes/commentLikes.module').then(m => m.CommentLikesModule) },
{ path: 'cropCategories', loadChildren: () => import('./pages/user/cropCategories/cropCategories.module').then(m => m.CropCategoriesModule) },
{ path: 'deficiencyDisorders', loadChildren: () => import('./pages/user/deficiencyDisorders/deficiencyDisorders.module').then(m => m.DeficiencyDisordersModule) },
{ path: 'discusses', loadChildren: () => import('./pages/user/discusses/discusses.module').then(m => m.DiscussesModule) },
{ path: 'discussComments', loadChildren: () => import('./pages/user/discussComments/discussComments.module').then(m => m.DiscussCommentsModule) },
{ path: 'plantProtections', loadChildren: () => import('./pages/user/plantProtections/plantProtections.module').then(m => m.PlantProtectionsModule) },
{ path: 'productFaqs', loadChildren: () => import('./pages/user/productFaqs/productFaqs.module').then(m => m.ProductFaqsModule) },
{ path: 'seasonWises', loadChildren: () => import('./pages/user/seasonWises/seasonWises.module').then(m => m.SeasonWisesModule) },
{ path: 'orderDetails', loadChildren: () => import('./pages/user/orderDetails/orderDetails.module').then(m => m.OrderDetailsModule) },
{ path: 'products', loadChildren: () => import('./pages/user/products/products.module').then(m => m.ProductsModule) },
{ path: 'farms', loadChildren: () => import('./pages/user/farms/farms.module').then(m => m.FarmsModule) },
{ path: 'myPloatCalanders', loadChildren: () => import('./pages/user/myPloatCalanders/myPloatCalanders.module').then(m => m.MyPloatCalandersModule) },
{ path: 'myPlots', loadChildren: () => import('./pages/user/myPlots/myPlots.module').then(m => m.MyPlotsModule) },
{ path: 'pincodeMasters', loadChildren: () => import('./pages/user/pincodeMasters/pincodeMasters.module').then(m => m.PincodeMastersModule) },
{ path: 'discussTypes', loadChildren: () => import('./pages/user/discussTypes/discussTypes.module').then(m => m.DiscussTypesModule) },
{ path: 'limitedTimeSales', loadChildren: () => import('./pages/user/limitedTimeSales/limitedTimeSales.module').then(m => m.LimitedTimeSalesModule) },
{ path: 'productLives', loadChildren: () => import('./pages/user/productLives/productLives.module').then(m => m.ProductLivesModule) },
{ path: 'buyOrders', loadChildren: () => import('./pages/user/buyOrders/buyOrders.module').then(m => m.BuyOrdersModule) },
{ path: 'coupons', loadChildren: () => import('./pages/user/coupons/coupons.module').then(m => m.CouponsModule) },
{ path: 'crops', loadChildren: () => import('./pages/user/crops/crops.module').then(m => m.CropsModule) },
{ path: 'cropDiseases', loadChildren: () => import('./pages/user/cropDiseases/cropDiseases.module').then(m => m.CropDiseasesModule) },
{ path: 'subscriptionPackages', loadChildren: () => import('./pages/user/subscriptionPackages/subscriptionPackages.module').then(m => m.SubscriptionPackagesModule) },
{ path: 'feedBacks', loadChildren: () => import('./pages/user/feedBacks/feedBacks.module').then(m => m.FeedBacksModule) },
{ path: 'raiseTickets', loadChildren: () => import('./pages/user/raiseTickets/raiseTickets.module').then(m => m.RaiseTicketsModule) },
{ path: 'allNotifications', loadChildren: () => import('./pages/user/allNotifications/allNotifications.module').then(m => m.AllNotificationsModule) },
{ path: 'plotPayments', loadChildren: () => import('./pages/user/plotPayments/plotPayments.module').then(m => m.PlotPaymentsModule) },
{ path: 'userNotifies', loadChildren: () => import('./pages/user/userNotifies/userNotifies.module').then(m => m.UserNotifiesModule) },
  { path: 'soilReports', loadChildren: () => import('./pages/user/soilReports/soilReports.module').then(m => m.SoilReportsModule) },
  { path: 'premiumPackages', loadChildren: () => import('./pages/user/premiumPackages/premiumPackages.module').then(m => m.PremiumPackagesModule) },

  {
    path: '**',
    loadChildren: () => import('./pages/pnf/pnf.module').then(m => m.PnfModule)
  }
// <<route-path-user>>
  // End AppUser Route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
