import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, catchError, switchMap, retry, filter } from 'rxjs/operators';
import { AsyncValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

import * as services from '../core/service';

 import { LookupServiceBase } from '../sharedBase/lookupService';
//import { LookupServiceBase } from '@ojas-enterprise/baseapp/dist/sharedBase';
import { AppInjector } from '../../app-injector.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends LookupServiceBase {

  getService(type: string) {
    let service: any = null;
    const injector = AppInjector.getInjector();
    //console.log('injector', injector);
    type = type[0].toLowerCase() + type.substring(1);
    switch (type) {

      case 'user':
        service = injector.get(services.UsersService);
        break;
 case 'webUser':
service = injector.get(services.WebUsersService);
break;
 case 'enumDetail':
service = injector.get(services.EnumDetailsService);
break;
 case 'appUser':
service = injector.get(services.AppUsersService);
break;
 case 'billingAddress':
service = injector.get(services.BillingAddressesService);
break;
 case 'cart':
service = injector.get(services.CartsService);
break;
 case 'commonData':
service = injector.get(services.CommonDatasService);
break;
 case 'commonDataDefault':
service = injector.get(services.CommonDataDefaultsService);
break;
 case 'configuration':
service = injector.get(services.ConfigurationsService);
break;
 case 'contact':
service = injector.get(services.ContactsService);
break;
 case 'contentHtml':
service = injector.get(services.ContentHtmlsService);
break;
 case 'contentHtmlDefault':
service = injector.get(services.ContentHtmlDefaultsService);
break;
 case 'document':
service = injector.get(services.DocumentsService);
break;
 case 'home':
service = injector.get(services.HomesService);
break;
 case 'i18n':
service = injector.get(services.I18nService);
break;
 case 'importData':
service = injector.get(services.ImportDatasService);
break;
 case 'login':
service = injector.get(services.LoginsService);
break;
 case 'order':
service = injector.get(services.OrdersService);
break;
 case 'orderDetail':
service = injector.get(services.OrderDetailsService);
break;
 case 'buyOrder':
service = injector.get(services.BuyOrdersService);
break;
 case 'allDocumentUpload':
service = injector.get(services.AllDocumentUploadsService);
break;
 case 'banner':
service = injector.get(services.BannersService);
break;
 case 'category':
service = injector.get(services.CategoriesService);
break;
 case 'coupon':
service = injector.get(services.CouponsService);
break;
 case 'crop':
service = injector.get(services.CropsService);
break;
 case 'cropDisease':
service = injector.get(services.CropDiseasesService);
break;
 case 'cropDiseaseMapping':
service = injector.get(services.CropDiseaseMappingsService);
break;
 case 'cropProperties':
service = injector.get(services.CropPropertiesService);
break;
 case 'district':
service = injector.get(services.DistrictsService);
break;
 case 'farm':
service = injector.get(services.FarmsService);
break;
 case 'farmSoilData':
service = injector.get(services.FarmSoilDatasService);
break;
 case 'farmWeatherData':
service = injector.get(services.FarmWeatherDatasService);
break;
 case 'forgetPassword':
service = injector.get(services.ForgetPasswordsService);
break;
 case 'lab':
service = injector.get(services.LabsService);
break;
 case 'labAppointment':
service = injector.get(services.LabAppointmentsService);
break;
 case 'notification':
service = injector.get(services.NotificationsService);
break;
 case 'packageSubscriptionHistory':
service = injector.get(services.PackageSubscriptionHistoriesService);
break;
 case 'plotFaqMapping':
service = injector.get(services.PlotFaqMappingsService);
break;
 case 'plotFaqQueAns':
service = injector.get(services.PlotFaqQueAnsService);
break;
 case 'product':
service = injector.get(services.ProductsService);
break;
 case 'productImage':
service = injector.get(services.ProductImagesService);
break;
 case 'productTag':
service = injector.get(services.ProductTagsService);
break;
 case 'pushNotificationMapper':
service = injector.get(services.PushNotificationMappersService);
break;
 case 'pushNotificationMessage':
service = injector.get(services.PushNotificationMessagesService);
break;
 case 'state':
service = injector.get(services.StatesService);
break;
 case 'subscriptionPackage':
service = injector.get(services.SubscriptionPackagesService);
break;
 case 'wallet':
service = injector.get(services.WalletsService);
break;
 case 'walletTransaction':
service = injector.get(services.WalletTransactionsService);
break;
 case 'wishList':
service = injector.get(services.WishListsService);
break;
 case 'productFarmer':
service = injector.get(services.ProductFarmersService);
break;
 case 'commentLike':
service = injector.get(services.CommentLikesService);
break;
 case 'cropCategory':
service = injector.get(services.CropCategoriesService);
break;
 case 'deficiencyDisorder':
service = injector.get(services.DeficiencyDisordersService);
break;
 case 'discuss':
service = injector.get(services.DiscussesService);
break;
 case 'discussComment':
service = injector.get(services.DiscussCommentsService);
break;
 case 'plantProtection':
service = injector.get(services.PlantProtectionsService);
break;
 case 'productFaq':
service = injector.get(services.ProductFaqsService);
break;
 case 'seasonWise':
service = injector.get(services.SeasonWisesService);
break;
 case 'otp':
service = injector.get(services.OtpsService);
break;
 case 'myPloatCalander':
service = injector.get(services.MyPloatCalandersService);
break;
 case 'myPlot':
service = injector.get(services.MyPlotsService);
break;
 case 'pincodeMaster':
service = injector.get(services.PincodeMastersService);
break;
 case 'soilReport':
service = injector.get(services.SoilReportsService);
break;
 case 'discussType':
service = injector.get(services.DiscussTypesService);
break;
 case 'limitedTimeSale':
service = injector.get(services.LimitedTimeSalesService);
break;
 case 'productLive':
service = injector.get(services.ProductLivesService);
break;
 case 'feedBack':
service = injector.get(services.FeedBacksService);
break;
 case 'raiseTicket':
service = injector.get(services.RaiseTicketsService);
break;
 case 'allNotification':
service = injector.get(services.AllNotificationsService);
break;
 case 'plotPayment':
service = injector.get(services.PlotPaymentsService);
break;
 case 'userNotify':
service = injector.get(services.UserNotifiesService);
break;
 case 'premiumPackage':
service = injector.get(services.PremiumPackagesService);
break;
 case 'product_Hi':
service = injector.get(services.Product_HiService);
break;
 case 'product_Mr':
service = injector.get(services.Product_MrService);
break;
 case 'limitedTimeSale_Hi':
service = injector.get(services.LimitedTimeSale_HiService);
break;
 case 'limitedTimeSale_Mr':
service = injector.get(services.LimitedTimeSale_MrService);
break;
 case 'category_Hi':
service = injector.get(services.Category_HiService);
break;
 case 'category_Mr':
service = injector.get(services.Category_MrService);
break;
 case 'deficiencyDisorder_Hi':
service = injector.get(services.DeficiencyDisorder_HiService);
break;
 case 'deficiencyDisorder_Mr':
service = injector.get(services.DeficiencyDisorder_MrService);
break;
 case 'plantProtection_Hi':
service = injector.get(services.PlantProtection_HiService);
break;
 case 'plantProtection_Mr':
service = injector.get(services.PlantProtection_MrService);
break;
 case 'cropDisease_Hi':
service = injector.get(services.CropDisease_HiService);
break;
 case 'cropDisease_Mr':
service = injector.get(services.CropDisease_MrService);
break;
 case 'plotFaqQueAns_Hi':
service = injector.get(services.PlotFaqQueAns_HiService);
break;
 case 'plotFaqQueAns_Mr':
service = injector.get(services.PlotFaqQueAns_MrService);
break;
 case 'subscriptionPackage_Hi':
service = injector.get(services.SubscriptionPackage_HiService);
break;
 case 'subscriptionPackage_Mr':
service = injector.get(services.SubscriptionPackage_MrService);
break;
 case 'premiumPackage_Hi':
service = injector.get(services.PremiumPackage_HiService);
break;
 case 'premiumPackage_Mr':
service = injector.get(services.PremiumPackage_MrService);
break;
 case 'notification_Hi':
service = injector.get(services.Notification_HiService);
break;
 case 'notification_Mr':
service = injector.get(services.Notification_MrService);
break;
 case 'allNotification_Hi':
service = injector.get(services.AllNotification_HiService);
break;
 case 'allNotification_Mr':
service = injector.get(services.AllNotification_MrService);
break;
 case 'pushNotificationMessage_Hi':
service = injector.get(services.PushNotificationMessage_HiService);
break;
 case 'pushNotificationMessage_Mr':
service = injector.get(services.PushNotificationMessage_MrService);
break;
 case 'productLive_Hi':
service = injector.get(services.ProductLive_HiService);
break;
 case 'productLive_Mr':
service = injector.get(services.ProductLive_MrService);
break;
 case 'crop_Hi':
service = injector.get(services.Crop_HiService);
break;
 case 'crop_Mr':
service = injector.get(services.Crop_MrService);
break;
 case 'enumDetail_Hi':
service = injector.get(services.EnumDetail_HiService);
break;
 case 'enumDetail_Mr':
service = injector.get(services.EnumDetail_MrService);
break;
// <<add-service>>
    }
    //console.log('service', service);
    return service;
  }

}
