import { Injectable } from '@angular/core';
import { ContentHtmlDefault } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class ContentHtmlDefaultsService extends BaseService<ContentHtmlDefault> {
  
    constructor() {
        super('ContentHtmlDefault');
    }
}
