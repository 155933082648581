import { Pipe, PipeTransform } from '@angular/core';
import { EnvService } from '../../sharedBase/env.service';

@Pipe({
  name: 'showImg'
})
export class ShowImgPipe implements PipeTransform {

  apiBaseUrl = '';

  constructor(public envService:EnvService) {

    this.apiBaseUrl = this.envService.apiUrl;
    // this.apiBaseUrl = 'https://api-examinds.wazl.in/';
  }

  transform(value: any, index: number = 0): any {
    const notFoundImgPath = '';
    try{
      if (value) {
        const files = JSON.parse(value);
        let d= this.getImgUrl(files[index]);
        // console.log('files',files,d);

        return d;
      }
    }
    catch {

    }

    return notFoundImgPath;
  }


  getImgUrl(file: any) {
    const startIndex = file.filePath.indexOf("ImportFiles/");
    // const result = file.filePath.substring(startIndex);
    // return this.apiBaseUrl +  result;
    return this.apiBaseUrl + 'ImportFiles/' + file.filePath;
  }

}
