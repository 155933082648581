import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'menuActive' })
export class menuActivePipe implements PipeTransform {
  constructor() {
  }
  transform(menuList: any[], value: string, type: string, currentRoute:string, mainMenu: string = '') {
    let isActive = false;
    const url = currentRoute.replace('admin/', '');
    if(!url){
      return false;
    }
    console.log('getCurrentRouteUrl', url);
    if (type == 'menu') {
      const menu = menuList.filter(a=> a.name == value && a.subMenu.split(',').indexOf(url) >= 0);
      isActive = menu?.length ? true : false;
    }
    if (type == 'subMenu') {
      const menu = menuList.filter(a=> a.name == mainMenu && a.subMenu.split(',').indexOf(value) >= 0 && value ==url);
      isActive = menu?.length ? true : false;
    }
    return isActive;
  }
}
