import { Injectable } from '@angular/core';
import { Product_Hi } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class Product_HiService extends BaseService<Product_Hi> {
  
    constructor() {
        super('Product_Hi');
    }
}
