import { Injectable } from '@angular/core';
import { DiscussComment } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class DiscussCommentsService extends BaseService<DiscussComment> {
  
    constructor() {
        super('DiscussComment');
    }
}
