import {
  Component,
  OnInit,
  Injectable,
  HostListener,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event,
  RouteConfigLoadStart,
} from "@angular/router";
import { HelperService } from "./shared/helperService";
import { LookupService } from "./shared/lookupService";
import { AppInjector } from "../app-injector.service";
import { MenuItem, PrimeNGConfig } from "primeng/api";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { LoginsService } from "./core/service";
import { environment } from "../environments/environment";
import { DOCUMENT } from "@angular/common";
import { BaseService } from "./sharedBase/baseService";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  showLoadingIndicator = true;
  menuActive = true;
  newsActive = false;
  isAdmin = false;
  isUserLoggedIn = false;
  loadIntialize = false;
  isMenuVisible: boolean = false;
  isOverlayVisible: boolean = false;
  loadComponent = false;

  loggedInUser: any;
  currentRoute: string;
  activeMenu: string = "";
  isWindowScroll: boolean = false;
  isLeftMenuFixed: boolean = false;
  isRightPartFixed: boolean = false;
  showMobileView: boolean = false;
  isMobSearchVisible: boolean = false;
  isMobProfileVisible: boolean = false;
  toggleNotification: boolean = false;
  isBlockVisible: boolean = true;
  isLoaderVisible: boolean = true;
  hideTooltip: boolean = false;
  userName: string;
  sidebar: boolean = true;
  url: any;
  items: MenuItem[];
  menuList: any[];
  currentUrl: '';
  loginLoadingComplete = false;
  domain = `${this.document.location.origin}}`;
  //  @HostListener('contextmenu')
  // preventContextMenu( ) {
  //     return true;
  // }

  //   @HostListener('contextmenu', ['$event'])
  // onRightClick(event) {
  //   if(!this.domain.includes('local')){
  //     event.preventDefault();
  //   }   else{
  //     return true;
  //   }
  // }
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private translate: TranslateService,
    public router: Router,
    public helperService: HelperService,
    public lookupService: LookupService,
    private renderer: Renderer2,
    private primengConfig: PrimeNGConfig
  ) {
    this.menuList = [
      { name: "home", subMenu: "home" },

      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },
      { name: "home", subMenu: "home" },

    ]
    translate.setDefaultLang("en");
    BaseService.setHelperService(helperService);
    const injector = AppInjector.getInjector();
    this.helperService.loginChange.subscribe((loginType) => {
      console.log("login change app", loginType);
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    });

    this.router.events.subscribe((routerEvent: Event) => {
      this.isMobSearchVisible = false;
      this.isMobProfileVisible = false;
      this.toggleNotification = !this.toggleNotification;
      if (routerEvent instanceof RouteConfigLoadStart) {
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
      }
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
        let currentUrl = routerEvent.url.substr(
          routerEvent.url.indexOf("/") + 1
        );
        this.currentRoute = currentUrl;
        console.log('currentUrl', currentUrl);
        this.activeMenu = this.getActiveMenu(routerEvent.url);
      }

      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError
      ) {
        setTimeout(() => {
          this.showLoadingIndicator = false;
        }, 500);

        setTimeout(() => {

        }, 500);


        setTimeout(() => {
          this.isLoaderVisible = false;
        }, 2000);
      }
    });
  }

  getActiveMenu(url: string): string {
    if (url.toLowerCase().indexOf("platters") >= 0) return "platters";
    if (url.toLowerCase().indexOf("investment-report") >= 0)
      return "investment-report";
    if (url.toLowerCase().indexOf("mandates") >= 0) return "mandates";
    if (url.toLowerCase().indexOf("bseRegistrations".toLowerCase()) >= 0)
      return "bseRegistrations";
    if (url.toLowerCase().indexOf("mfUserOrders".toLowerCase()) >= 0)
      return "mfUserOrders";
    if (
      url.toLowerCase().indexOf("edExpenses".toLowerCase()) >= 0 ||
      url.toLowerCase().indexOf("edIncomes".toLowerCase()) >= 0
    )
      return "tracker";
    if (url.toLowerCase().indexOf("contact-us") >= 0) return "contact-us";

    return "";
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    setTimeout(() => {
      this.lookupService.getDataEnum("").subscribe((list) => {
        this.loadIntialize = true;
      });
      this.getUserInfo();
    }, 50);

    GoogleAuth.initialize({
      clientId:
        "59039425859-6br974j38ki1f7jft7bbhle5v0reib2p.apps.googleusercontent.com",
      scopes: ["profile", "email"],
      grantOfflineAccess: true,
    });
    setTimeout(() => {
      this.loadIntialize = true;
      this.loadComponent = true;
    }, 1000);

    this.showMobileView = this.helperService.isMobile;

    if (!this.helperService.isMobile) {
      this.isMobSearchVisible = true;
    } else {
      this.isMobSearchVisible = false;
    }
    this.items = [
      {
        label: "Options",
        items: [
          {
            label: "Profile",
            icon: "pi pi-refresh",
            command: () => {
              this.router.navigateByUrl("profile");
            },
          },
          {
            label: "Settings",
            icon: "pi pi-user",
            command: () => { },
          },
          {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => {
              this.logout();
            },
          },
        ],
      },
    ];
  }

  getUserInfo(attempt: number = 0) {
    this.loggedInUser = this.helperService.getLoggedInUserInfo();
    console.log('getLoggedInUserInfo waiting', this.loggedInUser, attempt);
    if (attempt < 5) {
      if (!this.loggedInUser) {
        setTimeout(() => {
          this.getUserInfo(++attempt);
        }, 100);
      } else {
        this.isUserLoggedIn = this.helperService.isUserLoggedIn;
        this.isAdmin = this.helperService.isAdminUser;
        this.userName = this.helperService.loggedInUser.firstName;
        this.loginLoadingComplete = true;
      }
    }

    if (attempt >= 5) {
      this.loginLoadingComplete = true;
    }
  }

  ngAfterViewInit(): void {
    // setTimeout(()=>{
    //   this.userName=this.helperService.loggedInUser.firstName;
    // },100)
  }

  goto(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
  }
  onMenuButtonClick(event: any) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);
    loginSvc.clearData();
    this.helperService.logout();
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.isOverlayVisible = !this.isOverlayVisible;
  }

  gotoTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleMobileProfile(event: any): void {
    event.stopPropagation();
    this.isMobProfileVisible = !this.isMobProfileVisible;
  }

  toggleMobileSearch(event: any): void {
    event.stopPropagation();
    this.isMobSearchVisible = !this.isMobSearchVisible;
  }

  @HostListener("click", ["$event"])
  onClick(event: any): void {
    this.isMobProfileVisible = false;
    this.isMobSearchVisible = false;
    this.toggleNotification = false;
  }
  @HostListener("window:scroll", ["$event"]) fun() {
    if (window.innerWidth < 760) {
      this.hideTooltip = true;
    } else {
      this.hideTooltip = false;
    }
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      this.isWindowScroll = true;
    } else {
      this.isWindowScroll = false;
    }
  }
  // For Style
  closeSideMenu() {
    this.sidebar = !this.sidebar;
  }
  activeSidebar() {
    document.querySelector('.sidebar').classList.toggle('visible');
    document.querySelector('.adminNav').classList.toggle('shrink');
    document.querySelector('.blockLabel').classList.toggle('visible');
  }
}
