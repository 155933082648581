import { Injectable } from '@angular/core';
import { Farm } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class FarmsService extends BaseService<Farm> {
  
    constructor() {
        super('Farm');
    }
}
