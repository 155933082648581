import { Injectable } from '@angular/core';
import { Banner } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class BannersService extends BaseService<Banner> {
  
    constructor() {
        super('Banner');
    }
}
