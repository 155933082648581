import { Injectable } from '@angular/core';
import { LimitedTimeSale_Mr } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class LimitedTimeSale_MrService extends BaseService<LimitedTimeSale_Mr> {
  
    constructor() {
        super('LimitedTimeSale_Mr');
    }
}
