import { Injectable } from '@angular/core';
import { AllNotification_Hi } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class AllNotification_HiService extends BaseService<AllNotification_Hi> {
  
    constructor() {
        super('AllNotification_Hi');
    }
}
