import { Injectable } from '@angular/core';
import { PlantProtection } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class PlantProtectionsService extends BaseService<PlantProtection> {
  
    constructor() {
        super('PlantProtection');
    }
}
