import { Injectable } from '@angular/core';
import { PlotPayment } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class PlotPaymentsService extends BaseService<PlotPayment> {
  
    constructor() {
        super('PlotPayment');
    }
}
