import { Injectable } from '@angular/core';
import { UserNotify } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class UserNotifiesService extends BaseService<UserNotify> {
  
    constructor() {
        super('UserNotify');
    }
}
