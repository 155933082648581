import { Injectable } from '@angular/core';
import { FeedBack } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class FeedBacksService extends BaseService<FeedBack> {
  
    constructor() {
        super('FeedBack');
    }
}
