import { Injectable } from '@angular/core';
import { ProductImage } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class ProductImagesService extends BaseService<ProductImage> {
  
    constructor() {
        super('ProductImage');
    }
}
