import { Injectable } from '@angular/core';
import { BuyOrder } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class BuyOrdersService extends BaseService<BuyOrder> {
  
    constructor() {
        super('BuyOrder');
    }
}
