import { Injectable } from '@angular/core';
import { SubscriptionPackage_Hi } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class SubscriptionPackage_HiService extends BaseService<SubscriptionPackage_Hi> {
  
    constructor() {
        super('SubscriptionPackage_Hi');
    }
}
