import { Injectable } from '@angular/core';
import { PlantProtection_Mr } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class PlantProtection_MrService extends BaseService<PlantProtection_Mr> {
  
    constructor() {
        super('PlantProtection_Mr');
    }
}
