import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { AppUser } from '../../../core/model';
import { AppUsersService as service } from '../../admin/appUsers/appUsers.service';


@Injectable({
  providedIn: 'root'
})

export class AppUsersService extends service {
  getUserDetails(condition: any) {
    const form = { form: null, condition: condition };
    return this.http.post(this.apiUrl + 'GetUserDetails', form)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  acceptReject(form: AppUser) {
    // const form = {'form': platterId,'id': 0};
    return this.http.post(this.apiUrl + 'AcceptReject', form)
      .pipe(
        map(data => {

          return data;
        }),

        catchError(this.handleError)

      );
  }

  registerUser(form: AppUser) {
    return this.http.post(this.apiUrl + 'Register', form)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
}
