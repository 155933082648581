import { Injectable } from '@angular/core';
import { LabAppointment } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class LabAppointmentsService extends BaseService<LabAppointment> {
  
    constructor() {
        super('LabAppointment');
    }
}
