import { Injectable } from '@angular/core';
import { CropDiseaseMapping } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class CropDiseaseMappingsService extends BaseService<CropDiseaseMapping> {
  
    constructor() {
        super('CropDiseaseMapping');
    }
}
