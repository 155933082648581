import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Subject } from "rxjs";
import { Location } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export abstract class HelperServiceBase {
  searchHistory: any[] = [];
  loggedInUser = undefined;
  token: string = "";
  expiryToken: string = "";
  tokenName = "token";
  expireTokenName = "expireTokenName";
  tokenInfo = "tokenInfo";
  homePage = '';
  public globalLoading = false;
  public loginChange = new Subject<string>();
  public loggedInUserComplete = new Subject<Object>();
  public renderer: Renderer2;
  urlNavigation = "";
  routeData: any = null;
  private history: string[] = [];
  constructor(
    public router: Router,
    private rendererFactory: RendererFactory2, private location: Location
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }
  back(): void {
    this.history.pop();
    console.log('this.history',this.history);
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/");
    }
  }
  clearInfo() {
    localStorage.removeItem(this.tokenInfo);
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem(this.expireTokenName);
    this.loggedInUser = undefined;
    this.token = "";
    this.expiryToken = "";
  }

  logout() {
    localStorage.removeItem(this.tokenInfo);
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem(this.expireTokenName);
    this.token = "";
    this.expiryToken = "";

    this.loggedInUser = undefined;
    //this.loginChange.next('logout');
    // if (this.homePage) {
    //   this.routeNavigate([this.homePage]);
    // }
  }

  routeNavigate(path: any) {
    if (this.isAdminUser) {
      if (path.indexOf("admin/") < 0) {
        this.router.navigate(["./" + "admin/" + path]);
      } else {
        this.router.navigate(["./" + path]);
      }
    } else {
      this.router.navigate(["./" + path]);
    }
  }

  getLoggedInUserInfo() {
    if (this.loggedInUser) {
      this.loggedInUserComplete.next(this.loggedInUser);
      return this.loggedInUser;
    } else {
      const val = localStorage.getItem(this.tokenInfo);
      if (val) {
        this.token = localStorage.getItem(this.tokenName);
        try {

          const userInfo = JSON.parse(atob(val));
          this.loggedInUser = userInfo[0];
          this.loggedInUserComplete.next(this.loggedInUser);
          return this.loggedInUser;
        } catch (ex) {

        }
      } else {

      }
    }
    return null;
  }

  setLoggedInUserInfo(userInfo) {
    const userInfoObj = JSON.parse(userInfo);
    this.loggedInUser = userInfoObj[0];
    localStorage.setItem(this.tokenInfo, btoa(userInfo));
    this.loginChange.next('login');
  }

  setUserInfo(userInfo: any) {
    const data = [];
    data.push(userInfo);
    this.setLoggedInUserInfo(JSON.stringify(data));
  }

  get isUserLoggedIn() {
    return this.getLoggedInUserInfo() !== null;
  }

  set globalLoadingVal(val: boolean) {
    this.globalLoading = val;
  }

  get isAdminUser() {
    const loggedInUser = this.getLoggedInUserInfo();
    return loggedInUser !== null && loggedInUser.isAdmin;
  }

  get userRole() {
    const loggedInUser = this.getLoggedInUserInfo();
    // console.log('isAdminUser => loggedInUser', loggedInUser);
    return loggedInUser !== null && loggedInUser.role;
  }

  set navigationUrl(url: string) {
    this.urlNavigation = url;
  }

  get navigationUrl() {
    const url = this.urlNavigation;
    this.urlNavigation = "";
    return url || "/home";
  }
  setToken(token) {
    this.token = token;
    localStorage.setItem(this.tokenName, token);
  }

  setExpireToken(dateToken) {
    this.expiryToken = dateToken;
    localStorage.setItem(this.expireTokenName, dateToken);
  }

  getExpireToken() {
    return localStorage.getItem(this.expireTokenName);// this.expiryToken;
  }
  getSearchHistory(path: any) {
    const search = this.searchHistory.filter((a) => a.path === path);
    if (search && search.length > 0) {
      return { ...search[0].search };
    }
    return null;
  }

  setSearchHistory(path: any, filter: any) {
    const search = this.searchHistory.filter((a) => a.path === path);
    if (search && search.length > 0) {
      search[0].search = filter;
    } else {
      this.searchHistory.push({ path: path, search: filter });
    }
  }

  scrollToTop(): void {
    let ionContent = document.getElementsByTagName("ion-content");
    let elemDivs = ionContent[1].getElementsByTagName("div");
    // console.log('elem',elemDivs);
    elemDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
  }

  scrollToSection(id: string): void {
    let elem = document.getElementById(id);
    elem?.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  getHistoryBackDataType() {
    var historyData = window.location.href.split('#');
    if (historyData.length > 1) {
      return historyData[1];
    }
    return "";
  }

  setHistoryBackDataType(type: string) {
    history.pushState({ id: type }, '', window.location.href.split('#')[0] + `#${type}`);
  }

  clearSearchHistory() {
    this.searchHistory = [];
  }

  convertTitleCase(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  convertCamelCase(text: string) {
    return text.charAt(0).toLocaleLowerCase() + text.slice(1);
  }

  reload() {
    const currentUrl = decodeURIComponent(this.router.url);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.routeNavigate(currentUrl);
    });
  }

  setRouteData(data: any) {
    this.routeData = data;
  }
  getRouteData() {
    const data = { ...this.routeData };
    this.routeData = null;
    return data;
  }

  getCurrentRouteUrl() {
    return this.router.url;
  }


  logInvalidControls(itemForm, reactiveForm = true) {
    const formControls = reactiveForm ? itemForm?.controls : itemForm?.form.controls;
    const invalidCtrls = [];
    Object.keys(formControls).forEach(prop => {
      if (formControls[prop]["status"] == "INVALID") {
        invalidCtrls.push(prop);
      }
    });

  }

  checkValidControls(itemForm: any, controls: string[], reactiveForm = false) {
    const formControls = reactiveForm ? itemForm?.controls : itemForm?.form.controls;
    for (var key in formControls) {
      formControls[key].touched = true;
    }
    var hasInvalid = controls.some(function (ctrl) {
      return !(formControls[ctrl] ? formControls[ctrl].valid : true);
    });
    return !hasInvalid;
  }

  public keyPressAlphaNumeric(event: any) {
    // regEx = regEx || "/[A-Z0-9]+/g"
    console.log(event);
    console.log("Target: ", event.target.value);
    const keyValue = event.target.value;

    var re = /[a-zA-Z0-9]+/g;
    const PanValue = (keyValue.match(re) || []).join("");
    event.target.value = PanValue;
  }
  public keyNumeric(event: any) {
    console.log(event);
    console.log("Target: ", event.target.value);
    const keyValue = event.target.value;
    var re = /[0-9]+/g;
    const inputValue = (keyValue.match(re) || []).join("");
    event.target.value = inputValue;
  }

  get isMobile(): boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  public scrollParentToChild(parent, child) {
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
    }
  }
}
