import { Injectable } from '@angular/core';
import { PushNotificationMessage_Mr } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class PushNotificationMessage_MrService extends BaseService<PushNotificationMessage_Mr> {
  
    constructor() {
        super('PushNotificationMessage_Mr');
    }
}
