import { Injectable } from '@angular/core';
import { WishList } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class WishListsService extends BaseService<WishList> {
  
    constructor() {
        super('WishList');
    }
}
