import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { AppUser } from '../../../core/model';
import { AppUsersService as service } from '../../user/appUsers/appUsers.service';


@Injectable({
    providedIn: 'root'
})

export class AppUsersService extends service {
    deleteUser() {
        const form = {id:0};
        return this.http.post(this.apiUrl + 'DeleteUser', form)
        .pipe(
            map(data => {
              return data;
            }),
            catchError(this.handleError)
          );
      }
}
