import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
    term = term.length ? term.trim() : '';
    if (!term) return value;
    //return (value || []).filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    let result = value || [];
    const splitTerm = term.split(' ');
    splitTerm.forEach(searchTerm => {
      result = result.filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])));
    })
    return result;

  }
}
