import { Injectable } from '@angular/core';
import { RaiseTicket } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class RaiseTicketsService extends BaseService<RaiseTicket> {
  
    constructor() {
        super('RaiseTicket');
    }
}
