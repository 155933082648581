import { Component, OnInit, Input } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-chart-view',
  templateUrl: './chart-view.component.html',
  styleUrls: ['./chart-view.component.scss']
})
export class ChartViewComponent implements OnInit {

  @Input() option:any;
  constructor() { }

  ngOnInit(): void {
    this.initStatewiseChart();
  }

  initStatewiseChart() {
    const chartDom = document.getElementById('chartType')!;
    const myChart = echarts.init(chartDom);
    myChart.setOption(this.option);
  }

}
