import { Injectable } from '@angular/core';
import { PincodeMaster } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class PincodeMastersService extends BaseService<PincodeMaster> {
  
    constructor() {
        super('PincodeMaster');
    }
}
