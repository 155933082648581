import { Injectable } from '@angular/core';
import { Login } from '../model';
import { BaseService } from '../../shared/baseService';
import { catchError, map } from 'rxjs';
import { HelperService } from '../../shared/helperService';

@Injectable({
    providedIn: 'root'
})

export class LoginsService extends BaseService<Login> {
  constructor(helperService: HelperService) {
      super('Login', helperService);
  }
  validate(login: Login) {
    return this.http.post<any>(this.apiUrl + 'Validate', login)
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  sendLoginOtp(mobileNo) {
    return this.http.post<any>(this.apiUrl + 'SendLoginOtp', {'mobileNo': mobileNo})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  validateOtp(mobileNo, otp) {
    return this.http.post<any>(this.apiUrl + 'ValidateOtp', {'mobileNo': mobileNo, 'otp': otp})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  ValidateEmail(emailId, token) {
    return this.http.post<any>(this.apiUrl + 'ValidateEmail', {'emailId': emailId, 'token': token})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  loginMobile(mobileNo, otp) {
    return this.http.post<any>(this.apiUrl + 'LoginMobile', {'mobile': mobileNo, 'otp': otp})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  loginEmail(emailId, token,rememberme) {
    return this.http.post<any>(this.apiUrl + 'LoginEmail', {'emailId': emailId, 'otp': token,'rememberme':rememberme})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }
  loginPin(emailId, pin) {
    return this.http.post<any>(this.apiUrl + 'LoginPin', {'emailId': emailId, 'pin': pin})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  getTokenUserInfo(token: string) {
    const request = { token: token }
    return this.http.post<any>(this.apiUrl + 'GetTokenUserInfo', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }
  sendLoginOtpToken(mobileNo,smsToken) {
    return this.http.post<any>(this.apiUrl + 'SendLoginOtpToken', {'mobileNo': mobileNo,'smsToken':smsToken})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

}

